.bg-lgtech-light {
    background-color: #F5FCFF;
}

.bg-lgtech-dark {
    background-color: #011F2B;
}

.btn-lgtech {
    background-color: #A6D035;
    color: #011F2B;
}